import { isEnum } from "../../utils/enum/isEnum.js";
import type { News } from "../News.js";
import { VertiNodeLocale } from "../VertiNodeLocale.js";
import { newsPathMap } from "./newsPathMap.js";

const isVertiNodeLocale = isEnum(VertiNodeLocale);

export function getNewsDetailPath(blog: Pick<News, "locale" | "urlSlug">): string {
	if (!isVertiNodeLocale(blog.locale)) {
		throw new Error(`Can't get news detail path for locale ${blog.locale}`);
	}
	return `${newsPathMap[blog.locale]}/${blog.urlSlug}`;
}
