import type { SharedLocale } from "../schema/SharedLocale.js";

const dateMonthYearFormat: Partial<Record<SharedLocale, Intl.DateTimeFormat>> = {};
function createFormat(locale: SharedLocale): Intl.DateTimeFormat {
	dateMonthYearFormat[locale] ??= new Intl.DateTimeFormat(locale, {
		day: "numeric",
		month: "numeric",
		year: "numeric",
		timeZone: "Europe/Prague",
	});
	return dateMonthYearFormat[locale];
}

export function formatDateTime(date: number | Date | string, locale: SharedLocale): string {
	return createFormat(locale).format(typeof date === "string" ? new Date(date) : date);
}
